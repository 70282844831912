var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{staticClass:"mx-auto my-5"},[_c('v-card-title',[_vm._v("Subscriptions")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"data-cy":"subscriptions","headers":_vm.headers,"id":"subscriptions","items":_vm.subscriptionsByCostCenter,"options":_vm.pageDataSubscriptionsByCostCenter.options,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100, -1],
        'show-current-page': true,
        'show-first-last-page': true,
      },"items-per-page":_vm.pageDataSubscriptionsByCostCenter.options.itemsPerPage,"server-items-length":_vm.pageDataSubscriptionsByCostCenter.totalItems,"item-key":"id"},on:{"update:options":function($event){return _vm.$set(_vm.pageDataSubscriptionsByCostCenter, "options", $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{key:item.id},[(_vm.$keycloak.realmAccess.roles.includes('admin'))?_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(item.remoteId)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('a',_vm._g({staticStyle:{"text-decoration":"none","font-weight":"bold"},attrs:{"target":"_blank","rel":"noopener","href":_vm.safeUrl(item)}},{ ...tooltip }),[_vm._v(" "+_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v("Open Subscription in Azure Portal")])]),_c('v-icon',[_vm._v("mdi-open-in-new")])],1),_c('td',[_vm._v(" "+_vm._s(item.subscriptionType)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.managementGroupName)+" ")])])]}}])}),(_vm.inProgress)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.subscriptionTableAlert.message)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.subscriptionTableAlert.message)+" "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.getSubscriptionsByCostCenter({
            pageData: _vm.pageDataSubscriptionsByCostCenter,
            parentId: _vm.$router.currentRoute.params.id,
          })}}},[_c('v-icon',[_vm._v("mdi-cached")])],1)],1):_vm._e()],1),_c('v-spacer'),_c('v-container'),_c('v-spacer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }