import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  emptyServiceAgreement,
  InterfaceServiceAgreement,
  InterfaceServiceAgreementSearchRequest,
  InterfaceServiceAgreementSearchResult,
} from "@/models/ServiceAgreement.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import { SearchByKeywordRequest } from "@/models/Search.model";

import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/service-agreements`;

interface FetchParams {
  search: string;
  page: number;
  organizationId: string;
}

@Module({ namespaced: true })
class ServiceAgreements extends VuexModule {
  public existingServiceAgreements: InterfaceServiceAgreement[] = [];
  public pageDataServiceAgreements: PageData = emptyPageData();
  public pageDataSearchServiceAgreements: PageData = emptyPageData();
  public selectedServiceAgreement: InterfaceServiceAgreement =
    emptyServiceAgreement();
  public foundServiceAgreements: InterfaceServiceAgreement[] = [];
  public serviceAgreementAlert: Alert = emptyAlert();
  public serviceAgreementTableAlert: Alert = emptyAlert();
  public serviceAgreementDetailAlert: Alert = emptyAlert();
  public inProgress = false;
  public serviceAgreementAutocompleteLoadMore = false;
  public billingMonth = "";
  public rangeDate: string[] = [];

  @Mutation
  public setLoadMore(boolean: boolean): void {
    this.serviceAgreementAutocompleteLoadMore = boolean;
  }

  @Mutation
  public setServiceAgreements(data: InterfaceServiceAgreement[]): void {
    this.existingServiceAgreements = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataServiceAgreements.totalItems = pageData.totalItems;
  }

  @Mutation
  public setSearchPageData(pageData: PageData): void {
    this.pageDataSearchServiceAgreements.totalPages = pageData.totalPages;
  }

  @Mutation
  public setSelectedServiceAgreement(data: InterfaceServiceAgreement): void {
    this.selectedServiceAgreement = data;
  }

  @Mutation
  public setFoundServiceAgreements(data: InterfaceServiceAgreement[]): void {
    this.foundServiceAgreements = data;
  }

  @Mutation
  public setServiceAgreementAlert(data: Alert): void {
    this.serviceAgreementAlert = data;
  }

  @Mutation
  public clearServiceAgreementAlert(): void {
    this.serviceAgreementAlert = emptyAlert();
  }

  @Mutation
  public setServiceAgreementTableAlert(data: Alert): void {
    this.serviceAgreementTableAlert = data;
  }

  @Mutation
  public clearServiceAgreementTableAlert(): void {
    this.serviceAgreementTableAlert = emptyAlert();
  }

  @Mutation
  public setServiceAgreementDetailAlert(data: Alert): void {
    this.serviceAgreementDetailAlert = data;
  }

  @Mutation
  public clearServiceAgreementDetailAlert(): void {
    this.serviceAgreementDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Mutation
  public setBillingMonth(data: string): void {
    this.billingMonth = data;
  }
  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      const data = error.response.data as { message: string };
      alert = new Alert(error.response.status, data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setServiceAgreementAlert", alert);
    return false;
  }

  @Action
  public async storeServiceAgreement(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}`, {
        serviceAgreementId: serviceAgreement.serviceAgreementId,
        organizationId: serviceAgreement.organizationId,
        companyName: serviceAgreement.companyName,
        departmentName: serviceAgreement.departmentName,
        costCenter: serviceAgreement.costCenter,
        costCenterResponsible: serviceAgreement.costCenterResponsible,
        costCenterResponsibleEmailAddress:
          serviceAgreement.costCenterResponsibleEmailAddress,
        costCenterAdditionalResponsible:
          serviceAgreement.costCenterAdditionalResponsible,
        costCenterAdditionalResponsibleEmailAddress:
          serviceAgreement.costCenterAdditionalResponsibleEmailAddress,
        customerContactPerson: serviceAgreement.customerContactPerson,
        customerContactPersonEmailAddress:
          serviceAgreement.customerContactPersonEmailAddress,
        serviceProviderCompanyName: serviceAgreement.serviceProviderCompanyName,
        serviceProviderDepartmentName:
          serviceAgreement.serviceProviderDepartmentName,
        serviceProviderCostCenter: serviceAgreement.serviceProviderCostCenter,
        serviceProviderContactPerson:
          serviceAgreement.serviceProviderContactPerson,
        serviceProviderContactPersonEmailAddress:
          serviceAgreement.serviceProviderContactPersonEmailAddress,
        serviceProviderSignee: serviceAgreement.serviceProviderSignee,
        serviceProviderSigneeEmailAddress:
          serviceAgreement.serviceProviderSigneeEmailAddress,
        serviceProviderAdditionalSignee:
          serviceAgreement.serviceProviderAdditionalSignee,
        serviceProviderAdditionalSigneeEmailAddress:
          serviceAgreement.serviceProviderAdditionalSigneeEmailAddress,
        serviceDescription: serviceAgreement.serviceDescription,
        serviceName: serviceAgreement.serviceName,
        amount: serviceAgreement.amount,
        currency: serviceAgreement.currency,
        paymentSchedule: serviceAgreement.paymentSchedule,
        billingPeriodStart: serviceAgreement.billingPeriodStart,
        billingPeriodEnd: serviceAgreement.billingPeriodEnd,
        dateSent: serviceAgreement.dateSent,
        dateSigned: serviceAgreement.dateSigned,
        status: serviceAgreement.status,
        dataProcessingAgreement: serviceAgreement.dataProcessingAgreement,
        costBreakdown: serviceAgreement.costBreakdown,
      })
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getServiceAgreements(
    searchRequest: SearchByKeywordRequest
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageData.options.page}`,
      pageSize: `${searchRequest.pageData.options.itemsPerPage}`,
    }).toString();
    this.context.commit("setInProgress", true);
    this.context.commit("clearServiceAgreementTableAlert");
    let searchKeyword = "";
    if (searchRequest.keyword != "" && searchRequest.keyword != undefined) {
      searchKeyword = "/search/" + searchRequest.keyword;
    }
    return axios
      .get(`${baseApiUrl}${searchKeyword}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setServiceAgreements", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setServiceAgreementTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getOrganizationServiceAgreements(
    fetchParams: FetchParams
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: fetchParams.page + "",
      pageSize: `5`,
    }).toString();
    let searchKeyword = "";
    if (fetchParams.search != "" && fetchParams.search != null) {
      searchKeyword = "/search/".concat(fetchParams.search);
    }
    this.context.commit("setServiceAgreements", []);
    return axios
      .get(
        `${baseApiUrl}/organizations/${fetchParams.organizationId}${searchKeyword}?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setServiceAgreements", response.data.list);
        this.context.commit("setSearchPageData", response.data.paging);
        if (response.data.paging.pageIndex < response.data.paging.totalPages) {
          this.context.commit("setLoadMore", true);
        } else {
          this.context.commit("setLoadMore", false);
        }
        return true;
      });
  }

  @Action
  public async getServiceAgreement(id: string): Promise<boolean> {
    this.context.commit("setSelectedServiceAgreement", emptyServiceAgreement());
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedServiceAgreement", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          const data = error.response.data as { message: string };
          alert = new Alert(error.response.status, data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setServiceAgreementDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async updateServiceAgreement(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<boolean> {
    return axios
      .patch(`${baseApiUrl}/${serviceAgreement.id}`, serviceAgreement)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async sendServiceAgreement(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}/${serviceAgreement.id}/send`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async deleteServiceAgreement(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<boolean> {
    return axios
      .delete(`${baseApiUrl}/${serviceAgreement.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async searchServiceAgreement(
    searchRequest: InterfaceServiceAgreementSearchRequest
  ): Promise<InterfaceServiceAgreementSearchResult> {
    this.context.commit("setFoundServiceAgreements", []);
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageIndex}`,
      pageSize: `${searchRequest.pageSize}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}/search/${searchRequest.serviceAgreementId}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setFoundServiceAgreements", response.data.list);
        return <InterfaceServiceAgreementSearchResult>{
          paging: response.data.paging,
          serviceAgreements: response.data.list,
        };
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return <InterfaceServiceAgreementSearchResult>{};
      });
  }
}

export default ServiceAgreements;
